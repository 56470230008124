import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import useTickets from "../../DataProviders/tickets/useTickets";

import useClickOut from "../../utils/hooks/useClickOut";
import usePrevious from "../../utils/hooks/usePrevious";

import { Button, Chip, List, SearchInput } from "ui-components";

import Detail from "../Tickets/Detail";

import { lightFormat, format } from "date-fns";

import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";

import { getTicketStatusAvatar } from "ui-components";

import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

registerLocale("es", es);

let currentSearchText = "";

const Tickets = () => {
  const { t } = useTranslation();

  const TICKET_STATUS_OPTIONS = [
    {
      label: t("new"),
      value: "new",
      color: "236, 117, 74",
    },
    {
      label: t("open"),
      value: "open",
      color: "161, 178, 78",
    },
    {
      label: t("closed"),
      value: "closed",
      color: "237, 90, 95",
    },
  ];

  const getColumns = ({ t }) => {
    return [
      {
        id: "status",
        label: t("sta"),
        width: 26,
        maxWidth: 26,
        orderId: "status",
        renderCell: value => {
          return (
            <div className="avatar">
              <div className={`avatar__initial flex h-center v-center ${value}`}>{getTicketStatusAvatar(value)}</div>
              <div className="avatar__tip">{t(value)}</div>
            </div>
          );
        },
      },
      {
        id: "ticketNumber",
        label: t("id"),
        width: 100,
        maxWidth: 100,
        orderId: "ticketNumber",
        renderCell: (value, ticket) => (
          <div className="flex">
            <div className={ticket.imParticipating ? "Tickets__participating flex v-center" : ""}>
              <div>{value}</div>
              {ticket.imParticipating && <img src="assets/icons/participating.svg" />}
            </div>
          </div>
        ),
      },
      {
        id: "lastActivity",
        label: t("lastAct"),
        width: 99,
        maxWidth: 99,
        orderId: "lastActivity",
        renderCell: value => lightFormat(new Date(value), "dd/MM/yy HH:mm"),
      },
      {
        id: "title",
        label: t("subject"),
        orderId: "title",
      },
      {
        id: "source",
        label: t("from"),
        orderId: "source.name",
        renderCell: value => value.name,
      },
      {
        label: t("lastActivity2"),
        orderId: "lastActivity",
        renderCell: (_, obj) => {
          const lastActivity = lightFormat(new Date(obj.lastActivity), "dd/MM/yy HH:mm");
          const { name, surname, company } = obj.lastUser;
          const fullName = (name ? name + " " : "") + (surname ? surname : "");
          const lastUser = ` - ${company?.name || ""} (${fullName})`;
          return lastActivity + lastUser;
        },
      },
      {
        id: "filecase",
        label: t("nFilecase"),
        width: 116,
        maxWidth: 116,
        orderId: "filecase",
        renderCell: value => value || "-",
      },
    ];
  };

  const statusRef = useRef();
  const firstRender = useRef(true);

  const dateRef = useRef();

  const [searchIsClearable, setSearchIsClearable] = useState(false);

  const [showStatus, setShowStatus] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const [orderBy, setOrderBy] = useState({ status: "asc" });
  const prevOrderBy = usePrevious(orderBy);

  const [showDate, setShowDate] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [teamFilter, setTeamFilter] = useState([]);
  const [teamFilterCount, setTeamFilterCount] = useState(0);
  const [myTicketsFilter, setMyTicketsFilter] = useState(false);

  const [statusFilter, setStatusFilter] = useState({
    new: false,
    closed: false,
    open: false,
  });

  const [statusFilterCount, setStatusFilterCount] = useState(0);

  const {
    data: {
      list,
      totalDocs,
      params: { page, limit },
    },
    methods: {
      fetchList,
      fetchTicketDetail,
      getUserTeams,
      handleChangeFilters,
      handleChangeSort,
      handleChangeSearch,
      handleChangePage,
      handleChangePageSize,
    },
  } = useTickets(true);

  useClickOut(statusRef, () => setShowStatus(false));
  useClickOut(dateRef, () => setShowDate(false));

  const handleDoubleClick = async row => {
    await fetchTicketDetail(row._id);
    setShowDetail(true);
  };

  const handleSearch = search => {
    currentSearchText = search || "";
    setSearchIsClearable(!!search);
    handleChangeSearch(search);
  };

  const handleSortColumn = column => {
    const prevColumn = Object.keys(prevOrderBy)[0];
    if (prevColumn === column) setOrderBy({ [column]: prevOrderBy[prevColumn] === "asc" ? "desc" : "asc" });
    else setOrderBy({ [column]: "asc" });
  };

  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (end) {
      setShowDate(false);
    }
  };

  const handleClearDate = e => {
    onChange([null, null]);
    e.preventDefault();
    e.stopPropagation();
  };

  const fetchTeams = async () => {
    const response = await getUserTeams();
    setTeamFilter(
      response.map(item => ({
        ...item,
        active: false,
      }))
    );
  };

  useEffect(() => {
    if (firstRender.current) return;
    const filter = {};
    const status = Object.keys(statusFilter).filter(item => statusFilter[item]);
    setStatusFilterCount(status.length);
    const teams = teamFilter.filter(item => item.active).map(item => item.id);
    setTeamFilterCount(teams.length);
    if (status.length !== TICKET_STATUS_OPTIONS.length) {
      filter.status = status;
    }
    if (teamFilter.length !== teams.length) {
      filter["destination.teamId"] = teams;
    }
    if (startDate && endDate) {
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      filter["lastActivity"] = { $gte: startDate, $lt: endDate };
    }
    if (myTicketsFilter) {
      filter["mineOnly"] = true;
    }
    handleChangeFilters(filter);
  }, [statusFilter, endDate, startDate, teamFilter, myTicketsFilter]);

  useEffect(() => {
    const orderArr = Object.keys(orderBy).map(key => (orderBy[key] === "asc" ? `${key}` : `-${key}`));
    handleChangeSort(orderArr.join(","));
    firstRender.current = false;
  }, [orderBy]);

  useEffect(() => {
    fetchTeams();
  }, []);

  return (
    <>
      <List
        t={t}
        id="SupportCenter"
        hide={!list || showDetail}
        totalDocs={totalDocs}
        title={t("ticketList.ticketWithCount", { count: totalDocs || 0 })}
        headerFilters={
          <>
            <Button variant="secondary" onClick={() => setShowStatus(!showStatus)}>
              <>
                <img className="showOnExpanded active" src="/assets/icons/filter.svg" />
                <img className="showOnCompressed active" src="/assets/icons/filter-active.svg" />
              </>
              <div className="textToHide">
                {t("statuses")}
                {` (${statusFilterCount + teamFilterCount + 1})`}
              </div>
            </Button>
            {showStatus && (
              <div className="Tickets__header__filters__dropdown" ref={statusRef}>
                <div className="Tickets__header__filters__dropdown__section">
                  <div className="Tickets__header__filters__dropdown__section__title">{t("status").toUpperCase()}</div>
                  <div className="Tickets__header__filters__dropdown__section__content flex ">
                    {TICKET_STATUS_OPTIONS.map((item, index) => (
                      <Chip
                        color={item.color}
                        width="103px"
                        hasInactiveColor={true}
                        label={item.label}
                        isActive={statusFilter[item.value]}
                        key={index}
                        onChange={value => setStatusFilter({ ...statusFilter, [item.value]: value })}
                      />
                    ))}
                  </div>
                </div>
                <div className="Tickets__header__filters__dropdown__section">
                  <div className="Tickets__header__filters__dropdown__section__title">
                    {t("supportTeam").toUpperCase()}
                  </div>
                  <div className="Tickets__header__filters__dropdown__section__content flex">
                    {teamFilter.map((item, index) => (
                      <Chip
                        color="236, 117, 74"
                        label={item.name}
                        isActive={item.active}
                        key={index}
                        onChange={value => {
                          teamFilter[index] = { ...teamFilter[index], active: value };
                          setTeamFilter([...teamFilter]);
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="Tickets__header__filters__dropdown__section">
                  <div className="Tickets__header__filters__dropdown__section__title">
                    {t("responsable").toUpperCase()}
                  </div>
                  <div className="Tickets__header__filters__dropdown__section__content flex">
                    <Chip
                      color="236, 117, 74"
                      width="160px"
                      label={t("assignedToMe")}
                      isActive={myTicketsFilter}
                      onChange={value => setMyTicketsFilter(value)}
                    />
                    <Chip
                      color="236, 117, 74"
                      width="160px"
                      label={t("teamTickets")}
                      isActive={!myTicketsFilter}
                      onChange={value => setMyTicketsFilter(!value)}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="dateFilter">
              <Button
                variant="secondary"
                onClick={() => {
                  setShowStatus(false);
                  setShowDate(!showDate);
                }}
              >
                <img src="/assets/icons/calendar-filter.svg" />
                {startDate && endDate ? (
                  <>
                    {format(new Date(startDate), "dd/MM", { locale: es })} {`- `}
                    {format(new Date(endDate), "dd/MM", { locale: es })}
                    <img src="/assets/icons/close.svg" onClick={handleClearDate} />
                  </>
                ) : (
                  t("fromUntil")
                )}
              </Button>
              {showDate && (
                <div className="Tickets__header__filters__date__calendar" ref={dateRef}>
                  <DatePicker
                    isClearable
                    disabledKeyboardNavigation
                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    locale="es"
                  />
                </div>
              )}
            </div>
          </>
        }
        headerActions={
          <div className="PageHeader__actions__search">
            <SearchInput
              onChange={handleSearch}
              placeholder={t("searchByTerm")}
              defaultValue={currentSearchText}
              tip={t("wholeWordSearch")}
              clearable={searchIsClearable}
            />
          </div>
        }
        list={[...list]}
        columns={getColumns({ t })}
        pageData={{ page, limit }}
        pageActions={{
          onChangePage: handleChangePage,
          onChangePageSize: handleChangePageSize,
          onDoubleClick: handleDoubleClick,
        }}
        params={{ orderBy, onSort: handleSortColumn }}
      />

      {showDetail && <Detail
        onClose={() => {
          setShowDetail(false);
          //currentSearchText = "";
          setOrderBy({ status: "asc" });
          setStartDate(new Date());
          setEndDate(null);
          setTeamFilter([]);
          setTeamFilterCount(0);
          setMyTicketsFilter(false);
          setStatusFilter({
            new: false,
            closed: false,
            open: false,
          });
          setStatusFilterCount(0);
          if (currentSearchText) {
            handleSearch(currentSearchText);
          } else {
            fetchList();
          }
          //fetchList();
        }}
        isSupport={true}
      />}
    </>
  );
};

export default Tickets;
